import React from 'react';
import classNames from 'classnames';
import {Block} from "../Block";
import {BlockTitle} from "../BlockTitle";
import {PageMainColumnWrapper} from "../PageMainColumnWrapper";
import {SubserviceLink} from "../SubserviceLink";

const styles = require('./ServiceV2SubservicesBlock.module.scss')

export function ServiceV2SubservicesBlock({className, title, subservices, ...otherProps}) {
    return <Block appearance="subtle" className={styles.root}>
        <PageMainColumnWrapper>
            <div className={classNames(styles.root, className)} {...otherProps}>
                <BlockTitle className={styles.title}>{title}</BlockTitle>
                <div className={styles.subservices}>
                    {subservices.map((x, i) => {
                        return <SubserviceLink
                            key={i}
                            title={x.title}
                            description={x.description}
                            icon={x.icon}
                            slug={x.slug}
                            hasContent={x.hasContent}
                        />;
                    })}
                </div>
            </div>
        </PageMainColumnWrapper>
    </Block>
}
