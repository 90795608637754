import React from 'react';
import classNames from 'classnames';
import * as styles from './Breadcrumbs.module.scss';
import {LangLink} from "../LangLink";
import {componentRef} from "../../functions/componentRef";

export function Breadcrumbs({children, className, ...otherProps}) {
    const arr = React.Children.toArray(children);
    return <nav className={classNames(className, styles.root)} {...otherProps} {...componentRef('breadcrumbs')}>
        {React.Children.map(children, (child, index) => {
            const isLast = index === arr.length - 1;
            if (isLast) {
                return React.cloneElement(child, {
                    ...child.props,
                    isLast: true
                })
            }
            return child;
        })}
    </nav>;
}

Breadcrumbs.Item = ({className, isLast, to, ...otherProps}) => {
    const classes = classNames(className, styles.item);
    let Component = LangLink;
    let finalTo = to;
    if (isLast) {
        Component = 'span';
        finalTo = undefined;
    }
    return <Component {...componentRef.subcomponent('breadcrumb')} className={classes} to={finalTo} {...otherProps}/>;
}