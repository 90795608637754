import {Layout} from "../components/Layout";
import React from 'react';
import {ServiceV2SubservicesBlock} from "../components/ServiceV2SubservicesBlock";
import {graphql} from "gatsby";
import {Breadcrumbs} from "../components/Breadcrumbs";
import {Sections} from "../components/Sections";
import {SectionHero} from "../components/SectionHero";
import {useIntl} from "gatsby-plugin-react-intl";

export const query = graphql`
    query ServiceV3($locale: String!, $slug: String!) {
        service: contentfulServiceV3(slug: {eq: $slug}, node_locale: {eq: $locale}) {
            name
            isMain
            subservicesTitle
            sectionHero {
                ...SectionHero
            }
            subsubservices {
                name
                slug
                shortDescription {
                    shortDescription
                }
                content {
                    __typename
                }
                icon {
                    localFile {
                        url
                    }
                }
            }
            content {
                ...AllSectionsContent
            }
            seo {
                ...Seo
            }
        }
    }
`

export default function ServiceV3Page({data, pageContext}) {
    const intl = useIntl();
    return <Layout services={pageContext.services}
                   oldServices={pageContext.oldServices}
                   linkableSubServices={pageContext.linkableSubServices}
                   title={data.service?.name}
                   jobsCount={pageContext.jobsCount}
                   companyData={pageContext.companyData}
                   lang={pageContext.lang}>

        <SectionHero title={data.service.sectionHero.title}
                     name={data.service.name}
                     content={data.service.sectionHero.content}
                     image={data.service.sectionHero.image?.localFile}
                     ctaButton={data.service.sectionHero.ctaButton}
                     video={data.service.sectionHero.video}
                     breadcrumbs={
                         <Breadcrumbs>
                             <Breadcrumbs.Item to="/">{intl.formatMessage({id: 'menu.home'})}</Breadcrumbs.Item>
                             <Breadcrumbs.Item isLast={true}>{data.service.name}</Breadcrumbs.Item>
                         </Breadcrumbs>
                     }
        />
        {data.service.isMain && <ServiceV2SubservicesBlock
            title={data.service.subservicesTitle}
            subservices={
                (data.service.subsubservices || []).map(x => {
                    return {
                        title: x.name,
                        description: x.shortDescription.shortDescription,
                        icon: x.icon?.localFile?.url,
                        slug: x.slug,
                        hasContent: x.content?.length > 0
                    }
                })
            }
        />}
        <Sections
            sections={data.service.content}
        />
    </Layout>
}
